import React, { useEffect, useState } from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import Footer from "../components/footer";
import SEO from "../components/seo";
import { connect } from "react-redux";
import { appRedirect } from "../redux/app/actions";
import { anuncioGet, anuncioReset, anuncioSave } from "../redux/anuncios/actions";
import { useIntl, navigate } from "gatsby-plugin-intl";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import Tools from "../classes/tools";

const EditarAnuncio = ({
	location,
	loggedIn,
	authResolved,
	usuario,
	appRedirect,
	anuncioGet,
	anuncio,
	tablas,
	anuncioReset,
	boot,
	anuncioSave,
	s,
}) => {
	const intl = useIntl();

	useEffect(() => {
		anuncioReset();
	}, []);

	// si no esta logueado sacarlo de aca
	// y si esta logueado, tomar id de la url y cargar el anuncio
	// si no hay id, sacarlo de aca

	useEffect(() => {
		if (authResolved && !loggedIn) {
			appRedirect({ to: "/" });
		} else if (authResolved && loggedIn) {
			const pathParts = location.pathname.split("/");
			const id = parseInt(pathParts[pathParts.length - 1]);
			if (!id) {
				appRedirect({ to: "/micuenta" });
			} else {
				anuncioGet(intl.locale, id);
			}
		}
	}, [authResolved, loggedIn]);

	// campos del form

	const [tabla, setTabla] = useState("");
	const [tablaOtra, setTablaOtra] = useState("");
	const [title, setTitle] = useState("");
	const [descripcion, setDescripcion] = useState("");
	const [medidas, setMedidas] = useState("");
	const [operacion, setOperacion] = useState(null);
	const [precioAlquiler, setPrecioAlquiler] = useState("");
	const [precioVenta, setPrecioVenta] = useState("");
	const [lat, setLat] = useState("");
	const [long, setLong] = useState("");
	const [geoDescripcion, setGeoDescripcion] = useState("");
	const [puntoEncuentro, setPuntoEncuentro] = useState("");
	const [imagenes, setImagenes] = useState([]);

	// primero chequear que el anuncio sea del usuario, si no es, sacarlo de aca
	// si lo es, cargar el form con los datos del anuncio

	useEffect(() => {
		if (boot.loaded && authResolved && anuncio) {
			if (usuario.id !== anuncio.usuario.id) {
				appRedirect({ to: "/" });
			} else {
				if (anuncio.tabla) setTabla(anuncio.tabla.id);
				if (anuncio.tabla_otra) setTablaOtra(anuncio.tabla_otra);
				setTitle(anuncio.title);
				setDescripcion(anuncio.descripcion);
				setMedidas(anuncio.medidas);
				setOperacion(anuncio.operacion);
				setPrecioAlquiler(anuncio.precio_alquiler);
				setPrecioVenta(anuncio.precio_venta);
				setLat(anuncio.lat);
				setLong(anuncio.long);
				setGeoDescripcion(anuncio.geo_descripcion);
				setPuntoEncuentro(anuncio.punto_encuentro);

				const imagenesSrc = [];
				if (anuncio.imagenes && anuncio.imagenes.length > 0) {
					anuncio.imagenes.forEach((imagen) => {
						imagenesSrc.push(imagen.dataUrl);
					});
				}
				setImagenes(imagenesSrc);
			}
		}
	}, [boot.loaded, boot.paises, authResolved, anuncio]);

	const handleSetTabla = (tabla) => {
		if (tablaOtra) setTablaOtra("");
		setTabla(tabla);
	};

	const handleSetTablaOtra = (tablaOtra) => {
		if (tabla) setTabla("");
		setTablaOtra(tablaOtra);
	};

	// borrar imagen

	const borrarImagen = (index) => {
		const newImagenes = [...imagenes];
		newImagenes.splice(index, 1);
		setImagenes(newImagenes);
	};

	// cargar nueva imagen

	var imagenReader = null;

	try {
		imagenReader = new FileReader(); // si no esta dentro de un try, webpack o quien sea no encuentra la clase
		imagenReader.addEventListener(
			"load",
			() => {
				const img = new Image();
				img.onload = () => {
					let w = 800;
					let h = 800;
					if (img.width > img.height) {
						h = w / (img.width / img.height);
					} else {
						w = h / (img.height / img.width);
					}
					const canvas = document.createElement("canvas");
					canvas.width = w;
					canvas.height = h;
					const ctx = canvas.getContext("2d");
					ctx.drawImage(img, 0, 0, w, h);
					setImagenes([...imagenes, canvas.toDataURL("image/jpeg")]);
				};
				img.src = imagenReader.result;
			},
			false
		);
	} catch {}

	const handleImageAdd = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			const allowedTypes = ["image/jpeg", "image/png"];
			if (allowedTypes.indexOf(file.type) > -1) {
				imagenReader.readAsDataURL(e.target.files[0]);
			}
		}
	};

	const onPlaceSelected = (place) => {
		if (place.geometry?.location?.lat && place.geometry?.location?.lng && place.formatted_address) {
			setLat(place.geometry.location.lat());
			setLong(place.geometry.location.lng());
			setGeoDescripcion(place.formatted_address);
		}
	};

	const handleSave = (e) => {
		e.preventDefault();
		anuncioSave(intl.locale, {
			token: usuario.token,
			id: anuncio.id,
			tabla,
			tabla_otra: tablaOtra,
			title,
			descripcion,
			medidas,
			operacion,
			precio_alquiler: precioAlquiler,
			precio_venta: precioVenta,
			lat,
			long,
			geo_descripcion: geoDescripcion,
			punto_encuentro: puntoEncuentro,
			imagenes,
		});
	};

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="board-edit">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title={Tools.convertString(s.editaranuncio?.title)} />
					<main className="page form-page lightgray-bg-01">
						{/* EDIT */}
						{anuncio && (
							<div className="steps-wrapper">
								<article className="form-box box">
									<h4 className="black-text-01 prequel-font">{Tools.convertString(s.editaranuncio?.title)}</h4>
									<div className="wrapper">
										<form onSubmit={handleSave}>
											{/* STEP 02 */}
											<fieldset>
												<b className="black-text-01">{Tools.convertString(s.editaranuncio?.info_tecnica)}</b>
												<label className="lightgray-text-04">
													{Tools.convertString(s.editaranuncio?.titulo)}*
													<input
														className="input lightgray-bg-01 black-text-03"
														placeholder={Tools.convertString(s.editaranuncio?.titulo_placeholder)}
														type="text"
														value={title}
														onChange={(e) => setTitle(e.target.value)}
													/>
												</label>
												<label className="lightgray-text-04">
													{Tools.convertString(s.editaranuncio?.descripcion)}*
													<input
														className="input lightgray-bg-01 black-text-03"
														placeholder={Tools.convertString(s.editaranuncio?.descripcion_placeholder)}
														type="text"
														value={descripcion}
														onChange={(e) => setDescripcion(e.target.value)}
													/>
												</label>
												<label className="lightgray-text-04">
													{Tools.convertString(s.editaranuncio?.medidas)}
													<input
														className="input lightgray-bg-01 black-text-03"
														placeholder={Tools.convertString(s.editaranuncio?.medidas_placeholder)}
														type="text"
														value={medidas}
														onChange={(e) => setMedidas(e.target.value)}
													/>
												</label>
											</fieldset>
											{/* /STEP 02 */}

											{/* STEP 03 */}
											<fieldset>
												<b className="black-text-01">{Tools.convertString(s.editaranuncio?.precio)}</b>
												<label className="lightgray-text-04">
													{Tools.convertString(s.editaranuncio?.operacion)}*
													<div className="buttonSet">
														<button
															type="button"
															className={operacion === 1 && "active"}
															onClick={() => setOperacion(1)}
														>
															{Tools.convertString(s.crearanuncio?.alquiler)}
														</button>
														<button
															type="button"
															className={operacion === 2 && "active"}
															onClick={() => setOperacion(2)}
														>
															{Tools.convertString(s.crearanuncio?.venta)}
														</button>
														<button
															type="button"
															className={operacion === 3 && "active"}
															onClick={() => setOperacion(3)}
														>
															{Tools.convertString(s.crearanuncio?.alquilerventa)}
														</button>
													</div>
												</label>
												{(operacion === 1 || operacion === 3) && (
													<label className="lightgray-text-04">
														{Tools.convertString(s.editaranuncio?.precio_alquiler)}*
														<input
															className="input lightgray-bg-01 black-text-03"
															placeholder={Tools.convertString(s.editaranuncio?.precio_alquiler_placeholder)}
															value={precioAlquiler}
															type="number"
															onChange={(e) => setPrecioAlquiler(e.target.value)}
														/>
														<small className="lightgray-text-04">
															{Tools.convertString(s.editaranuncio?.precio_alquiler_texto)}
														</small>
													</label>
												)}
												{(operacion === 2 || operacion === 3) && (
													<label className="lightgray-text-04">
														{Tools.convertString(s.editaranuncio?.precio_venta)}*
														<input
															className="input lightgray-bg-01 black-text-03"
															placeholder={Tools.convertString(s.editaranuncio?.precio_venta_placeholder)}
															value={precioVenta}
															type="number"
															onChange={(e) => setPrecioVenta(e.target.value)}
														/>
														<small className="lightgray-text-04">
															{Tools.convertString(s.editaranuncio?.precio_venta_texto)}
														</small>
													</label>
												)}
											</fieldset>
											{/* /STEP 03 */}

											{/* STEP 04 */}
											<fieldset>
												<b className="black-text-01">{Tools.convertString(s.editaranuncio?.ubicacion)}</b>
												<label className="lightgray-text-04">
													{Tools.convertString(s.editaranuncio?.geo)}
													<ReactGoogleAutocomplete
														className="input lightgray-bg-01 black-text-03"
														onPlaceSelected={onPlaceSelected}
														placeholder={Tools.convertString(s.crearanuncio?.geo_placeholder)}
														defaultValue={geoDescripcion}
													/>
												</label>
												<label className="lightgray-text-04">
													{Tools.convertString(s.editaranuncio?.punto_encuentro)}*
													<input
														className="input lightgray-bg-01 black-text-03"
														placeholder={Tools.convertString(s.editaranuncio?.punto_encuentro_placeholder)}
														type="text"
														value={puntoEncuentro}
														onChange={(e) => setPuntoEncuentro(e.target.value)}
													/>
													<small className="lightgray-text-06">
														{Tools.convertString(s.editaranuncio?.punto_encuentro_texto)}
													</small>
												</label>
											</fieldset>
											{/* /STEP 04 */}

											{/* STEP 05 */}
											<fieldset>
												<b className="black-text-01">{Tools.convertString(s.editaranuncio?.fotos)}</b>
												<span className="wrapper-imgs">
													{imagenes && imagenes.length > 0 && (
														<>
															{imagenes.map((imagen, index) => {
																return (
																	<div key={index}>
																		<img className="profile-img" src={imagen} key={index} alt={``} />
																		<a
																			className="black-bg-01 close-button delete-img"
																			onClick={borrarImagen.bind(null, index)}
																		></a>
																	</div>
																);
															})}
														</>
													)}
													<label className="black-text-01 file-upload" htmlFor="editarAnuncioImagen">
														<img className="profile-img" src="/img/upload-icon.svg" alt="Tu imagen" />

														<input
															type="file"
															className="file"
															size="29"
															length="4096"
															name="editarAnuncioImagen"
															id="editarAnuncioImagen"
															onChange={handleImageAdd}
														/>
													</label>
												</span>
												<small className="lightgray-text-06">{Tools.convertString(s.editaranuncio?.fotos_aclaracion)}</small>
												{/* <label className="black-text-01 file-upload" htmlFor="file">
													Sube fotografías de la tabla*
													<input type="file" className="file" size="29" length="4096" required />
													<input className="upload lightgray-bg-01" />
												</label>
												<small className="lightgray-text-06">Formatos .jpg y .png</small> */}
											</fieldset>
											{/* /STEP 05 */}

											{/* STEP 01 */}
											<fieldset className="no-margin">
												<b className="black-text-01">{Tools.convertString(s.editaranuncio?.tabla_tipo)}</b>
												<ul className="board-type">
													{tablas.map((t) => {
														return (
															<li key={t.id}>
																<label className="lightgray-text-04">
																	<input
																		className="input black-text-03"
																		name="board-type"
																		type="radio"
																		checked={t.id === tabla}
																		onChange={handleSetTabla.bind(null, t.id)}
																	/>
																	<span className="lightgray-bg-03">
																		<img src={t.imagen.url} alt={t.title} />
																	</span>
																</label>
																<p className="align-center black-text-01">{t.title}</p>
															</li>
														);
													})}
												</ul>
												<label className="lightgray-text-04">
													{Tools.convertString(s.editaranuncio?.tabla_otra)}
													<input
														className="input lightgray-bg-01 black-text-03"
														placeholder={Tools.convertString(s.editaranuncio?.tabla_otra_placeholder)}
														type="text"
														value={tablaOtra}
														onChange={(e) => handleSetTablaOtra(e.target.value)}
													/>
												</label>
											</fieldset>
											{/* /STEP 01 */}

											<div className="button-wrapper lightgray-bg-01">
												<button type="submit" className="button black-bg-03 white-text">
													{Tools.convertString(s.editaranuncio?.guardar)}
												</button>
											</div>
										</form>
									</div>
								</article>
							</div>
						)}
						{/* /EDIT */}
						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	const { loggedIn, resolved: authResolved, usuario } = state.auth;
	const { anuncio } = state.anuncios;
	const { boot } = state.app;
	const { tablas } = state.app.boot;
	return {
		loggedIn,
		authResolved,
		usuario,
		anuncio,
		tablas,
		boot,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appRedirect,
	anuncioGet,
	anuncioReset,
	anuncioSave,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditarAnuncio);
